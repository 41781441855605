"use strict";
/*!
 *
 *  toggler.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Toggler = void 0;
var Toggler = /** @class */ (function () {
    function Toggler(elem, option) {
        this.hasHeadWrapper = false;
        this.setting = {
            head: '.head',
            body: '.body',
            autoClose: false,
            aria: false,
            viewport: {
                phone: true,
                tablet: true,
                desktop: true,
                largeDesktop: true
            }
        };
        this.$elem = $(elem);
        $.extend(true, this.setting, option);
        this.$head = this.$elem.find(this.setting.head);
        // if( !this.$head.is('a') ){
        // 	this.hasHeadWrapper = true;
        // 	this.$head = this.$head.find('a');
        // };
        this.$body = this.$elem.find(this.setting.body);
        this.headExpandClass = this.getExpandClass(this.$head, this.setting.head);
        this.bodyExpandClass = this.getExpandClass(this.$body, this.setting.body);
        this.setEvent();
    }
    Toggler.prototype.expand = function (e) {
        e.preventDefault();
        if (this.setting.viewport[util.viewport]) {
            var $this = $(e.currentTarget);
            var $target = this.getTarget($this);
            if (this.setting.aria) {
                if ($this.is('[aria-selected="true"]')) {
                    $this.attr('aria-selected', 'false');
                    $target.attr('aria-hidden', 'true');
                }
                else {
                    $this.attr('aria-selected', 'true');
                    $target.attr('aria-hidden', 'false');
                }
                ;
            }
            else {
                if ($this.is('.' + this.headExpandClass)) {
                    $this.removeClass(this.headExpandClass);
                    // $target.removeClass(this.bodyExpandClass);
                    $target.stop(false, true).animate({
                        height: 'hide'
                    }, 500, 'easeOutExpo');
                }
                else {
                    $this.addClass(this.headExpandClass);
                    // $target.addClass(this.bodyExpandClass);
                    $target.stop(false, true).animate({
                        height: 'show'
                    }, 500, 'easeOutExpo');
                }
                ;
            }
            ;
        }
        ;
    };
    Toggler.prototype.setEvent = function () {
        var _this = this;
        this.$head.on('click', function (e) { return _this.expand(e); });
        this.$head.on('keydown', function (e) {
            if (e.keyCode === 13) {
                _this.expand(e);
            }
            ;
        });
    };
    Toggler.prototype.getTarget = function ($elem) {
        var target = $elem.attr('href');
        if (target === undefined) {
            if (this.hasHeadWrapper) {
                return $elem.closest(this.setting.head).next(this.setting.body);
            }
            else {
                return $elem.next(this.setting.body);
            }
        }
        else {
            if (target.search('#') === 0) {
                return $(target);
            }
            else {
                if (this.hasHeadWrapper) {
                    return $elem.closest(this.setting.head).next(this.setting.body);
                }
                else {
                    return $elem.next(this.setting.body);
                }
            }
            ;
        }
        ;
    };
    Toggler.prototype.getExpandClass = function ($elem, cl) {
        // let elemClass = $elem.attr('class');
        // if (elemClass !== undefined) {
        // 	if (elemClass.search(cl.replace('.', '')) !== -1) {
        // 		return cl.replace('.', '') + '-expanded';
        // 	} else {
        // 		if (elemClass.search('.') === 0 && elemClass.search(' ') === -1) {
        // 			return elemClass + '-expanded';
        // 		} else {
        // 			return 'is-expanded'
        // 		}
        // 	}
        // }else{
        // 	return 'is-expanded';
        // }
        return '-expanded';
    };
    return Toggler;
}());
exports.Toggler = Toggler;
$.fn.toggler = function (option) {
    return this.each(function (i, elem) {
        new Toggler(elem, option ? option : {});
    });
};
