"use strict";
/*!
 *
 *  megamenu.ts
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var default_1 = /** @class */ (function () {
    function default_1() {
        var _this = this;
        this.$item = $('.st-Global_Item');
        this.$link = $('.st-Global_Link');
        this.$switch = $('.st-Global_Switch');
        this.$megamenuItem = $('.st-Megamenu_Item a');
        this.$item.on('mouseenter', function (e) {
            if (!util.isTouchDevice && util.viewport !== 'phone') {
                _this.open($(e.currentTarget));
            }
            ;
        }).on('mouseleave', function (e) {
            if (!util.isTouchDevice && util.viewport !== 'phone') {
                _this.close($(e.currentTarget));
            }
            ;
        });
        this.$link.on('click', function (e) {
            if (util.isTouchDevice && util.viewport !== 'phone') {
                var $elem = $(e.currentTarget).closest('.st-Global_Item');
                if (!$elem.is('.-expanded')) {
                    e.preventDefault();
                    if ($('.st-Global_Item.-expanded').length) {
                        var $close = $('.st-Global_Item.-expanded');
                        _this.close($close);
                    }
                    ;
                    _this.open($elem);
                }
                ;
            }
            ;
        });
        $(document).on('click', function (e) {
            if (util.isTouchDevice && util.viewport !== 'phone') {
                if (!$(e.target).closest('.st-Global_Item').length) {
                    var $close = $('.st-Global_Item.-expanded');
                    _this.close($close);
                }
                ;
            }
            ;
        });
        this.$switch.on('click', function (e) {
            e.preventDefault();
            var $elem = $(e.currentTarget).closest('.st-Global_Item');
            if (!$elem.is('.-expanded')) {
                if ($('.st-Global_Item.-expanded').length) {
                    var $close = $('.st-Global_Item.-expanded');
                    _this.close($close);
                }
                ;
                _this.open($elem);
            }
            else {
                _this.close($elem);
            }
            ;
        });
        this.$megamenuItem.on('click', function (e) {
            if (util.viewport === 'phone') {
                var $parent = $(e.currentTarget).closest('.st-Global_Item');
                _this.close($parent);
                $('#Menu').removeClass('-expanded');
            }
            ;
        });
    }
    default_1.prototype.open = function ($elem) {
        var $body = $elem.children('.st-Global_Body');
        if ($('.st-Global_Item.-expanded').length) {
            var $close = $('.st-Global_Item.-expanded');
            this.close($close);
        }
        ;
        if ($body.length) {
            $elem.addClass('-expanded');
            $body.stop(true, false).animate({
                height: 'show'
            }, 500, 'easeOutExpo');
        }
        ;
    };
    default_1.prototype.close = function ($elem) {
        var $body = $elem.children('.st-Global_Body');
        if ($body.length) {
            $elem.removeClass('-expanded');
            $body.stop(true, false).animate({
                height: 'hide'
            }, 500, 'easeOutExpo');
        }
        ;
    };
    return default_1;
}());
exports.default = default_1;
