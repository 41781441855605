"use strict";
/*!
 *
 *  linker.js
 *
 */
Object.defineProperty(exports, "__esModule", { value: true });
var default_1 = /** @class */ (function () {
    function default_1(option) {
        var _this = this;
        this.setting = {
            icon: {
                blank: 'blank',
                excel: 'xls',
                word: 'doc',
                pdf: 'pdf'
            },
            blankDir: undefined,
            extendLink: '.extend-link'
        };
        this.domain = document.domain;
        $.extend(true, this.setting, option ? option : {});
        this.setIcon();
        $(document).delegate('a', 'click', function (e) {
            if ($(e.currentTarget).is(_this.getTarget())) {
                e.preventDefault();
                var href = $(e.currentTarget).attr('href');
                window.open(href, '_blank');
            }
            ;
        });
        $(this.setting.extendLink).on('click', function (e) {
            e.preventDefault();
            var href = $(e.currentTarget).find('a').attr('href');
            var target = $(e.currentTarget).find('a').attr('target');
            if (target === undefined) {
                target = '_self';
            }
            ;
            window.open(href, target);
            return false;
        });
    }
    ;
    default_1.prototype.getTarget = function () {
        return 'a[target="_blank"],' +
            'a[href^="http://"]:not([href*="' + this.domain + '/"]):not(".-no-blank"):not(".sw-Modal"),' +
            'a[href^="https://"]:not([href*="' + this.domain + '/"]):not(".-no-blank"):not(".sw-Modal"),' +
            this.getBlankDirTarget() +
            'a[href*=".pdf"],' +
            'a[href*=".xls"], a[href*=".xlsx"],' +
            'a[href*=".doc"], a[href*=".docx"]';
    };
    default_1.prototype.getBlankDirTarget = function () {
        if (this.setting.blankDir !== undefined) {
            var target = '';
            for (var prop in this.setting.blankDir) {
                target += 'a[href ^= "/' + this.setting.blankDir[prop] + '/"],';
                target += 'a[href ^= "' + this.domain + '/' + this.setting.blankDir[prop] + '/"],';
            }
            ;
            return target.slice(0, -1);
        }
        else {
            return '';
        }
        ;
    };
    default_1.prototype.setIcon = function () {
        $(this.getTarget()).each(function (i, elem) {
            var href = $(elem).attr('href');
            if (href !== undefined) {
                $(elem).attr('target', '_blank');
            }
            ;
        });
    };
    return default_1;
}());
exports.default = default_1;
